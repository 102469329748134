<template>

  <div id="page-user-list">

    <vx-card class="mb-base">
        <div class="flex justify-between">
            <div class="flex justify-start md:w-4/12">
                <span class="mr-4 mt-1">Select Date : </span>
                <vs-input type="date" size="small" v-model="date"/>
            </div>
            <vs-button size="small" @click="exportData">Download to Excel</vs-button>
        </div>
        <div class="my-4 flex align-center justify-center">
            <h4>Daily Report</h4>
        </div>
        <vue-apex-charts type="bar" height="400" :options="graphicReport.options" :series="graphicReport.series"></vue-apex-charts>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import chartData from '../chartData.js'
import moment from 'moment'
import recipe from "@/recipe"

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            loaded: false,
            graphicReport: {
                series: [],
                options: {}
            },
            date: moment(new Date()).format('yyyy-MM-DD')
        }
    },
    methods : {
        exportData () {
            window.open(`${recipe}/api/v1/technical/ticketing/trouble-ticket/export/${this.date}/${this.date}/excel`, '_blank');
        },
        getGraphicReport () {
            const self = this
            this.$store.dispatch('report/fetchReportDaily', { 
                date: self.date
            })
            .then(res => {
                const data = res.data.data
                
                self.graphicReport.series  = data.series
                self.graphicReport.options = {
                    chart: {
                        height: 400,
                        type: 'bar',
                        events: {
                            click: function(chart, w, e) {
                                // console.log(chart, w, e)
                            }
                        }
                    },
                    // colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                    plotOptions: {
                        bar: {
                        columnWidth: '45%',
                        distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: data.categories,
                        labels: {
                            rotate: 0,
                            rotateAlways: false,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: false,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                // colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                                fontSize: '9px',
                                cssClass: 'apexcharts-xaxis-title'
                            }
                        }   
                    }
                } 
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    watch : {
        date () {
            this.getGraphicReport()
        }
    },
    created() {
        this.getGraphicReport()
    }
}
</script>
